<template>
    <section>
        <modal ref="modalSubirImagen" :titulo="titulo" tamano="modal-lg" @guardar="retornarImagen">
            <div class="row mx-0 justify-center">
                <slim-cropper
                ref="cropper"
                :options="slimOptions" class="border br-10"
                style="height:490px;width:490px;background:#F8F9FF;"
                >
                    <div slot="label" class="">
                        <i class="icon-star" />
                    </div>
                </slim-cropper>
            </div>
        </modal>
    </section>
</template>
<script>
export default {
    props: {
        imagen: {
            type: Object,
            default: () => ({})
        },
        titulo: {
            type: String ,
            default: 'Subir imagen'
        }
    },
    data(){
        return{
            slimOptions: {
                ratio: '1:1',
                size: { width: 650, height: 650 },
                minSize: { width: 100, height: 100 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            }
        }
    },
    methods: {
        toggle(){
            this.$refs.modalSubirImagen.toggle();
        },
        retornarImagen(){
            this.$emit('cargar', {
                ...(this.imagen.id && { id: this.imagen.id }),
                base64: this.$refs.cropper.instanciaCrop.dataBase64.output.image
            })
            this.toggle()
        }
    }
}
</script>
